import { withFormRenderWrapper } from '../../../HOCs/FormRenderWrapper';
import { hasAnyPermissions } from '../../../utils/helpers';
import withSettingsTabsWrapper from '../settings/components/withSettingsTabsWrapper';

import {
    TITLE_PREFIX,
    ORDERS_RULES_ROUTE,
    ORDERS_RULES_FORM_PATH,
    ORDERS_RULES_CREATE_FORM_ROUTE,
    ORDERS_RULES_EDIT_FORM_ROUTE,
} from './consts';

export default (app) => {
    const routers = {
        orderDecisionSettings: {
            path: app.settingsOrderTabs.getItem('orderDecisionSettings').link,
            title: app.settingsOrderTabs.getItem('orderDecisionSettings').title,
            permission: app.settingsOrderTabs.getItem('orderDecisionSettings').permission,
            breadcrumbConfig: {
                title: `${app.settingsOrderTabs.getItem('orderDecisionSettings').label}`,
                isTab: true,
                clearPath: true,
            },
            props: () => {},
            loader: () => import('@skunexus/order-decision-settings'),
            render: withSettingsTabsWrapper(app),
        },
        orderBulkDecisionSettings: {
            path: app.settingsOrderTabs.getItem('orderDecisionBulkSettings').link,
            title: app.settingsOrderTabs.getItem('orderDecisionBulkSettings').title,
            permission: app.settingsOrderTabs.getItem('orderDecisionBulkSettings').permission,
            breadcrumbConfig: {
                title: `${app.settingsOrderTabs.getItem('orderDecisionBulkSettings').label}`,
                isTab: true,
                clearPath: true,
            },
            props: () => {},
            loader: () => import('@skunexus/order-decision-bulk-settings'),
            render: withSettingsTabsWrapper(app),
        },
        orderDecisionRuleSettings: {
            path: app.settingsOrderTabs.getItem('orderDecisionRuleSettings').link,
            title: app.settingsOrderTabs.getItem('orderDecisionRuleSettings').title,
            permission: app.settingsOrderTabs.getItem('orderDecisionRuleSettings').permission,
            breadcrumbConfig: {
                title: `${app.settingsOrderTabs.getItem('orderDecisionRuleSettings').label}`,
                isTab: true,
                clearPath: true,
            },
            props: () => ({
                addFormPageRoute: ORDERS_RULES_CREATE_FORM_ROUTE,
                editFormPageRoute: ORDERS_RULES_EDIT_FORM_ROUTE,
                permissions: {
                    canAdd: hasAnyPermissions('orders - decision rules.create'),
                    withExport: true,
                    canManageRulePriorities: true,
                    canEdit:  hasAnyPermissions('orders - decision rules.update'),
                    canDelete:  hasAnyPermissions('orders - decision rules.delete'),
                    withOrderRuleLink: hasAnyPermissions('orders - decision rules.update')
                }
            }),
            loader: () => import('@skunexus/order-decision-rules-settings'),
            render: withSettingsTabsWrapper(app),
        },
        orderDecisionRuleForm: {
            path: ORDERS_RULES_FORM_PATH,
            title: `${TITLE_PREFIX} Decision Rules Form`,
            permission: ['orders - decision rules.create', 'orders - decision rules.update'],
            breadcrumbConfig: { title: 'Create Rule', isReplaceble: true },
            props: () => ({
                indexPageRoute: ORDERS_RULES_ROUTE,
                permissions: {
                    canAdd: hasAnyPermissions('orders - decision rules.create'),
                    canEdit: hasAnyPermissions('orders - decision rules.update')
                }
            }),
            loader: () => import('@skunexus/order-decision-rules-form'),
            render: withFormRenderWrapper(app)
        },
    };

    return routers;
};
