import {
    ORDERS_DECISIONS_TITLE,
    ORDERS_RULES_TITLE,
    ORDERS_BULK_DECISIONS_TITLE,
    ORDERS_DECISIONS_ROUTE,
    ORDERS_RULES_ROUTE,
    ORDERS_BULK_DECISIONS_ROUTE,
} from './consts';

export default () => ({
    orderDecisionSettings: {
        id: 'orderDecisionSettings',
        label: ORDERS_DECISIONS_TITLE,
        title: ORDERS_DECISIONS_TITLE,
        link: ORDERS_DECISIONS_ROUTE,
        permission: ['orders - decision rules.index'],
        order: 10,
    },
    orderDecisionRuleSettings: {
        id: 'orderDecisionRuleSettings',
        label: ORDERS_RULES_TITLE,
        title: ORDERS_RULES_TITLE,
        link: ORDERS_RULES_ROUTE,
        permission: ['orders - decision rules.index'],
        order: 20,
    },
    orderDecisionBulkSettings: {
        id: 'orderDecisionBulkSettings',
        label: ORDERS_BULK_DECISIONS_TITLE,
        title: ORDERS_BULK_DECISIONS_TITLE,
        link: ORDERS_BULK_DECISIONS_ROUTE,
        permission: ['orders - decision rules.index'],
        order: 20,
    },
});
